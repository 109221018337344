import { type ListResponse } from "../products/types";
import { type SMSNumber } from "./types";
import { getSession } from "~/services/session";

type SMSNumbersList = Omit<ListResponse<SMSNumber>, "Limit" | "Offset">;

export const listNumbers = async (): Promise<SMSNumbersList> => {
  const session = await getSession();

  const response = await fetch(`${import.meta.env.VITE_ROMA_API}/sms`, {
    headers: {
      Authorization: `Bearer ${session?.token}`,
    },
  });

  if (!response.ok) {
    // TODO
    throw new Error("Error retrieving SMS data");
  }

  const data: SMSNumbersList = await response.json();
  return data;
};
