import { createEffect } from "solid-js";
import { createAsync, useSubmission } from "@solidjs/router";
import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import * as z from "zod";
import { useSMSNumbers } from "~/services/sms";

import { AccountPanel } from "~/components/account";

export default function Mobile() {
  const numbers = createAsync(() => useSMSNumbers());

  createEffect(() => console.log("NUMBERS >>>> ", numbers()));

  return <p>This is the mobile page...</p>;
}
